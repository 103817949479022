import { BrowserRouter as Router } from 'react-router-dom';
import { PageRoutes } from 'Routes';

const App = () => {
  return (
    <Router>
      <PageRoutes />
    </Router>
  );
};

export default App;
