import { useState } from 'react';
import useForm from 'react-hook-form';
import { statuses } from 'api/issueLog.api';

import styles from 'assets/css/thankYou.css';

const MarkIssueInProgressForm = (props) => {
  const [requesting, setRequesting] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    const payload = {
      updatedById: props.userId,
      issueLogId: props.issueId,
      issueLogStatusType: statuses.IN_PROGRESS,
      comment: data.comment,
    };

    setRequesting(true);
    props.onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.title}>Issue marked as in progress</div>
      <div className={styles.subTitle}>
        You can add a note for your team below.
      </div>
      <div className={styles.commentInput}>
        <div className={styles.inputTitle}>Comment</div>
        {errors.comment && (
          <div className={styles.error}>{errors.comment.message}</div>
        )}
        <textarea
          autoFocus
          className={styles.input}
          placeholder=""
          name="comment"
          ref={register({
            maxLength: {
              value: 1000,
              message: 'Comment cannot be longer than 1000 characters.',
            },
          })}
        />
      </div>
      <div className={styles.btn_container}>
        <input
          type="submit"
          className={
            requesting
              ? `${styles.btn_main} ${styles.disabled_btn_main}`
              : styles.btn_main
          }
          disabled={requesting}
          value={requesting ? 'Submitting...' : 'Submit'}
        />
      </div>
    </form>
  );
};
export default MarkIssueInProgressForm;
