import { Link } from 'react-router-dom';
import t from 'js_utils/translations';
import globalStyles from '../assets/css/global_styles.css';

const NOT_FOUND = 'NOT_FOUND';
const EXPIRED = 'EXPIRED';
const USED = 'USED';

const ValidateResetToken = ({ resetTokenValidation }) => {
  const renderText = () => {
    switch (resetTokenValidation.invalidReason) {
      case NOT_FOUND:
        return t('login.passwordResetLinkInvalid');
      case EXPIRED:
        return t('login.passwordResetLinkExpired');
      case USED:
        return t('login.passwordResetLinkUsed');
      default:
        return t('login.passwordResetLinkInvalid');
    }
  };

  return (
    <div>
      <div
        className={`${globalStyles.rectangle} ${globalStyles.rectangle_err}`}
      >
        <div className={globalStyles.header_txt}>{renderText()}</div>
        <div className={globalStyles.body_txt}>
          Please go to the&nbsp;
          <Link to="/forgot-password" className={globalStyles.link_text}>
            Forgot Your Password
          </Link>
          &nbsp;page to request a new link.
        </div>
      </div>
    </div>
  );
};

export default ValidateResetToken;
