import Rollbar from 'rollbar';
import { getEnv } from 'js_utils/envFunctions';
import config from 'config';

let rollbarObj;

export const updateRollbarConfig = (codeVersion) => {
  const userId = localStorage.getItem('userId');
  rollbarObj = new Rollbar({
    accessToken: config.ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: getEnv(),
      person: { id: userId ? userId : null }, //person is key defined in rollbar and we are sending userId
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: codeVersion,
        },
      },
    },
  });
};

updateRollbarConfig('Early Rollbar user');

export const logErrorInfo = (error) => {
  const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error;
  console.warn(message);
  rollbarObj.error(error, { message: message });
};
