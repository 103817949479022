import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import t from 'js_utils/translations';
import { getStyle } from 'js_utils/styles';
import LoginLink from 'components/shared/LoginLink';

import globalStyles from '../assets/css/global_styles.css';
import styles from '../assets/css/confirmation.css';

const EmailConfirmation = (props) => {
  const stylesWl = getStyle(props.whiteLabel);
  useEffect(() => {
    if (!props.history.location.state) {
      props.history.push('/forgot-password');
    }
  }, [props.history]);

  return (
    <div>
      <div className={globalStyles.no_error}></div>
      <div className={`${stylesWl.rectangle} ${stylesWl.success_border}`}>
        <div className={styles.check}>
          <span className={styles.check_left}></span>
          <span className={styles.check_right}></span>
        </div>
        <div className={stylesWl.header_txt}>
          {t('login.passwordResetRequested')}
        </div>
        <div className={`${stylesWl.subtitle} ${stylesWl.subtitle_wider}`}>
          {t('login.emailConfirmation.line1')} &nbsp;
          <br />
          {t('login.emailConfirmation.line2')}
        </div>
        <LoginLink whiteLabel={props.whiteLabel} />
      </div>
    </div>
  );
};

export default withRouter(EmailConfirmation);
