import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const WHITE_LABEL_NAMES = {
  maersk: 'MAERSK',
  msc: 'MSC',
  hlag: 'HAPAG_LLOYD',
  cmacgm: 'CMA_CGM',
  '': '',
  MAERSK: 'maersk',
  MSC: 'msc',
  HAPAG_LLOYD: 'hlag',
  CMA_CGM: 'cmacgm',
};

export const loginUrls = Object.keys(WHITE_LABEL_NAMES);

export const getLoginUrl = (whiteLabel) =>
  whiteLabel ? `/${WHITE_LABEL_NAMES[whiteLabel]}` : '/';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
