import { axios } from 'index.js';

export const resetPassword = (payload) => {
  return axios.post('/webapp/reset-password', payload).then((res) => res);
};

export const resetPasswordRequest = (username) => {
  return axios
    .post('/webapp/request-password-reset', username)
    .then((res) => res);
};

export const validateResetToken = async (token) => {
  const { data } = await axios.post('/webapp/login/validate-token', { token });
  return data;
};
