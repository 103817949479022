import { axios } from 'index.js';

export const statuses = {
  CLOSED: 'CLOSED',
  IN_PROGRESS: 'IN_PROGRESS',
};

const issueLogStatusUpdate = (payload) => {
  return axios
    .post('/webapp/public/issue-logs/status', payload)
    .then((res) => res);
};

export const markStatusWithComments = (payload) => {
  return issueLogStatusUpdate(payload);
};

export const markInProgress = (userId, issueId) => {
  return issueLogStatusUpdate({
    updatedById: userId,
    issueLogId: issueId,
    issueLogStatusType: statuses.IN_PROGRESS,
  });
};

export const markClosed = (userId, issueId) => {
  return issueLogStatusUpdate({
    updatedById: userId,
    issueLogId: issueId,
    issueLogStatusType: statuses.CLOSED,
  });
};
