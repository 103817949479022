import styles from 'assets/css/thankYou.css';

const PPMPaymentForm = () => {
  return (
    <div className={styles.container}>
      <iframe
        title="PPM Payment"
        src="https://form.jotform.com/240165055744151"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default PPMPaymentForm;
