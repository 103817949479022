const validDomains = [
  'https://members.dev.nyshex.com',
  'https://login.dev.nyshex.com',
  'https://admin.dev.nyshex.com',
  'https://members.staging.nyshex.com',
  'https://login.staging.nyshex.com',
  'https://admin.staging.nyshex.com',
  'https://members.nyshex.com',
  'https://login.nyshex.com',
  'https://admin.nyshex.com',
  'https://members.salesdemo.nyshex.com',
  'https://login.salesdemo.nyshex.com',
  'https://admin.salesdemo.nyshex.com',
  'https://members.test.nyshex.com',
  'https://login.test.nyshex.com',
  'https://admin.test.nyshex.com',
];

export const isValidRedirect = (url) => {
  try {
    const paresedUrl = new URL(url);
    return validDomains.includes(paresedUrl.origin);
  } catch (e) {
    return false;
  }
};
