import { getYear } from 'date-fns';
import t from 'js_utils/translations';
import { getStyle } from 'js_utils/styles';

import styles from 'assets/css/footer.css';

const Footer = ({ whiteLabel }) => {
  const liveDisclaimer = t('login.liveDisclaimer');
  const footerStyles = getStyle(whiteLabel);

  return (
    <footer className={styles.footer}>
      <div className={footerStyles.disclaimer}>
        {liveDisclaimer}
        <a
          href="mailto:customer.support@nyshex.com"
          className={footerStyles.link}
        >
          &nbsp;customer.support@nyshex.com
        </a>
        .
      </div>
      <div className={footerStyles.extras}>
        <div>
          <i className="fa fa-copyright" aria-hidden="true"></i>
          {whiteLabel
            ? ` Powered by NYSHEX`
            : ` Copyright ${getYear(new Date())} NYSHEX`}
        </div>
        <div className={styles.emails}>
          <div>
            <i
              className={`fa fa-question-circle ${styles.icon}`}
              aria-hidden="true"
            />
            &nbsp;
            <a
              href="mailto:customer.support@nyshex.com?Subject=Need%20Help"
              className={footerStyles.link}
            >
              {t('login.needHelp')}
            </a>
          </div>
          {!whiteLabel && (
            <div>
              <a
                href="mailto:customer.support@nyshex.com?Subject=Request%20to%20delete%20user%20data%20and%20stop%20emails"
                className={footerStyles.link}
              >
                {t('login.forgetMe')}
              </a>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
