import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import t from 'js_utils/translations';
import styles from '../assets/css/confirmation.css';
import globalStyles from '../assets/css/global_styles.css';

const ResetConfirmation = (props) => {
  useEffect(() => {
    if (!props.history.location.state) {
      props.history.push('/');
    }
  }, [props.history]);

  return (
    <div>
      <div className={globalStyles.no_error}></div>
      <div className={`${globalStyles.rectangle} ${styles.success_border}`}>
        <div className={styles.check}>
          <span className={styles.check_left}></span>
          <span className={styles.check_right}></span>
        </div>
        <div className={globalStyles.header_txt}>
          {t('login.passwordUpdated')}
        </div>
        <div className={globalStyles.subtitle}>
          {t('login.newPasswordText')}
        </div>
        <button
          id="back-login"
          type="button"
          className={`${globalStyles.btn_main} ${globalStyles.reset_btn}`}
          onClick={() => props.history.push('/')}
        >
          {t('login.backToLogin')}
        </button>
      </div>
    </div>
  );
};

export default withRouter(ResetConfirmation);
