import { useState } from 'react';
import useForm from 'react-hook-form';
import { statuses } from 'api/issueLog.api';

import styles from 'assets/css/thankYou.css';

const CloseIssueForm = (props) => {
  const [requesting, setRequesting] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    const payload = {
      updatedById: props.userId,
      issueLogId: props.issueId,
      issueLogStatusType: statuses.CLOSED,
      initiatedParty: data.initiatingParty,
      comment: data.comment,
    };

    setRequesting(true);
    props.onSubmit(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.title}>Provide details on closing this issue</div>
      <div className={styles.initiatingPartyInput}>
        <div className={styles.inputTitle}>Initiating Party</div>
        <div className={styles.inputSubtitle}>
          For the purposes of reporting, how should this issue be categorized?
        </div>
        {errors.comment ? (
          <div className={styles.error}>{errors.initiatingParty.message}</div>
        ) : (
          <div></div>
        )}
        <div className={styles.input}>
          <label htmlFor="initiatingParty-carrier">
            <input
              ref={register('initiatingParty')}
              type="radio"
              name="initiatingParty"
              value="CARRIER"
              id="initiatingParty-carrier"
            />
            <span>Carrier-related</span>
          </label>
          <label htmlFor="initiatingParty-shipper">
            <input
              ref={register('initiatingParty')}
              type="radio"
              name="initiatingParty"
              value="SHIPPER"
              id="initiatingParty-shipper"
            />
            <span>Shipper-related</span>
          </label>
          <label htmlFor="initiatingParty-other">
            <input
              ref={register('initiatingParty')}
              type="radio"
              name="initiatingParty"
              value="OTHER"
              id="initiatingParty-other"
            />
            <span>Other</span>
          </label>
        </div>
      </div>
      <div className={styles.commentInput}>
        <div className={styles.inputTitle}>
          Action taken to close this issue
        </div>
        {errors.comment && (
          <div className={styles.error}>{errors.comment.message}</div>
        )}
        <textarea
          autoFocus
          className={styles.input}
          placeholder="E.g. I placed more bookings on this contract, I notified the trucker of the shipment delay, etc."
          name="comment"
          ref={register({
            maxLength: {
              value: 1000,
              message: 'Comment cannot be longer than 1000 characters.',
            },
          })}
        />
      </div>
      <div className={styles.btn_container}>
        <input
          type="submit"
          className={
            requesting
              ? `${styles.btn_main} ${styles.disabled_btn_main}`
              : styles.btn_main
          }
          disabled={requesting}
          value={requesting ? 'Submitting...' : 'Submit'}
        />
      </div>
    </form>
  );
};
export default CloseIssueForm;
