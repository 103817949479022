import styles from 'assets/css/thankYou.css';

const BasicSignUpForm = () => {
  return (
    <div className={styles.container}>
      <iframe
        title="Basic Sign Up"
        src="https://form.jotform.com/240174614881154"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default BasicSignUpForm;
