import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from 'js_utils/functions';
import { markStatusWithComments } from 'api/issueLog.api';

import CloseIssueForm from 'components/CloseIssueForm';
import MarkIssueInProgressForm from 'components/MarkIssueInProgressForm';

import styles from 'assets/css/thankYou.css';

const ThankYou = () => {
  const query = useQuery();
  const userId = query.get('user');
  const issueId = query.get('issueId');
  const issueStatus = query.get('status');

  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (payload) => {
    markStatusWithComments(payload).finally(() => {
      setSubmitted(true);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.logo}>&nbsp;</div>
      <div className={styles.card}>
        {submitted ? (
          <div>
            <div className={styles.title}>Thank you!</div>
            <div className={styles.subtitle}>
              Your response has been recorded.
            </div>
          </div>
        ) : issueStatus === 'In Progress' ? (
          <MarkIssueInProgressForm
            onSubmit={onSubmit}
            userId={userId}
            issueId={issueId}
          />
        ) : (
          <CloseIssueForm
            onSubmit={onSubmit}
            userId={userId}
            issueId={issueId}
          />
        )}
      </div>
      <Link to="/" className={styles.button}>
        Log in to NYSHEX
      </Link>
    </div>
  );
};

export default ThankYou;
