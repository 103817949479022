import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import t from 'js_utils/translations';

const styles = {
  container: {
    color: '#3e4043',
  },

  title: {
    fontSize: '24px',
    fontWeight: '400',
    color: '#cd2b1e',
  },

  text: {
    marginTop: '10px',
    lineHeight: '19px',
    fontWeight: '300',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cancel_btn: {
    backgroundColor: 'transparent',
    border: '1px solid #1e5b86 ',
    color: '#1e5b86',
    boxSizing: 'border-box',
    padding: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '13px',
    letterSpacing: '0.3px ',
    marginLeft: '20px',
  },

  accept_btn: {
    width: '228px',
    background: '#cd2b1e',
    borderColor: '#cd2b1e',
    color: '#fff',
    '&:hover': {
      background: '#ff3333',
    },
  },

  link: {
    color: '#1e5b86',
    fontWeight: 400,
    textDecoration: 'underline',
  },
};

const DeclineDialog = ({
  isDeclineOpen,
  toConsentModal,
  declineGdpr,
  closeModals,
  ...props
}) => {
  const { classes } = props;
  return (
    <div>
      <Dialog
        open={isDeclineOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeModals();
          }
        }}
      >
        <DialogTitle className={classes.title}>
          {t('login.declineNYSHEXTerms')}
        </DialogTitle>
        <DialogContent className={classes.text}>
          <DialogContentText>
            {t('login.declinePrivacyPolicy')}
          </DialogContentText>
          <DialogContentText>{t('login.gdprText')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancel_btn} onClick={toConsentModal}>
            {t('login.takeMeBack')}
          </Button>
          <Button
            className={`${classes.cancel_btn} ${classes.accept_btn}`}
            onClick={declineGdpr}
          >
            {t('login.declineButtonText')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(DeclineDialog);

DeclineDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isDeclineOpen: PropTypes.bool.isRequired,
  toConsentModal: PropTypes.func.isRequired,
  declineGdpr: PropTypes.func.isRequired,
  closeModals: PropTypes.func.isRequired,
};
