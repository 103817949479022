import { Switch, Route } from 'react-router-dom';
import Login from 'components/Login';
import ThankYou from 'components/ThankYou';
import PPMPaymentForm from 'components/PPMPaymentForm';
import BasicSignUpForm from 'components/BasicSignUpForm';

export const PageRoutes = () => {
  return (
    <Switch>
      <Route path="/thanks" component={ThankYou} />
      <Route path="/basic-sign-up" component={BasicSignUpForm} />
      <Route path="/ppm-payment" component={PPMPaymentForm} />
      <Route path="/" component={Login} />
    </Switch>
  );
};
