import amplitude from 'amplitude-js';
import config from 'config';
import { fetchApplicationVersion } from 'applicationVersionService';

const API_KEY = config.AMPLITUDE_API_KEY;

let client = null;

const getClient = () => {
  if (client) {
    return Promise.resolve(client);
  }
  const newClient = amplitude.getInstance();
  newClient.init(API_KEY);
  const promise = fetchApplicationVersion().then((version) => {
    newClient.setVersionName(`login-ui-${version}`);
    client = newClient;
    return newClient;
  });
  return promise;
};

/**
 * @param {object} eventTypeObject - component*, action*
 * @param {object} eventProperties - data about the specific event
 * @param {string} [userId]
 */
export const publishEvent = (
  { component, action },
  eventProperties,
  userId
) => {
  if (!API_KEY) return;
  const eventType = `LOGIN_UI:${component}:${action}`;
  return getClient().then((client) => {
    if (userId) {
      client.setUserId(userId);
    }
    client.logEvent(eventType, eventProperties);
  });
};
