import { useEffect } from 'react';
import useForm from 'react-hook-form';
import PropTypes from 'prop-types';
import t from 'js_utils/translations';
import { getStyle } from 'js_utils/styles';

import formStyles from '../assets/css/sign_in_form.css';
import globalStyles from '../assets/css/global_styles.css';

import { publishEvent } from 'analyticsService';

const publishClickRequestDemoLink = ({ link }) => {
  publishEvent(
    {
      component: 'LOGIN_PAGE',
      action: 'CLICK_REQUEST_DEMO_LINK',
    },
    { link }
  );
};

const SignInForm = (props) => {
  const {
    handleChange,
    toggleAcceptCookies,
    login,
    loginError,
    cookiesCheckmark,
    consentCookies,
    forgot,
    loggingIn,
    accountLocked,
    whiteLabel,
  } = props;
  const styles = getStyle(whiteLabel);
  useEffect(() => {
    if (props.gdprDeclined) {
      setValue('userName', '');
      setValue('userPassword', '');
    }
  });

  const handleCheckBoxKeyPress = (e) => {
    e.which === 32 && toggleAcceptCookies(); // space
    e.which === 13 && cookiesCheckmark && handleSubmit(onSubmit)(); // enter
  };

  const { register, setValue, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    if (!data) return;
    login(data);
  };

  const handleClickRequestDemoLink = (e) => {
    e.preventDefault();
    const link = 'https://nyshex.com/how-to-start';
    publishClickRequestDemoLink({ link });
    window.open(link);
  };

  const cookiesStatement = t('login.checkboxcookie');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {accountLocked && (
        <div className={globalStyles.account_locked}>{accountLocked}</div>
      )}
      {loginError.length ? (
        <div className={globalStyles.error}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          <span
            className={
              loginError.length < 60
                ? globalStyles.form_error
                : globalStyles.form_error_small
            }
            data-qa-id="login-error-message"
          >
            {loginError}
          </span>
        </div>
      ) : (
        <div className={globalStyles.no_error}></div>
      )}
      <div
        className={
          loginError.length
            ? !consentCookies
              ? styles.rectangle_err
              : styles.rectangle_nocookie_err
            : consentCookies
            ? styles.rectangle
            : styles.rectangle_nocookie
        }
      >
        <div className={styles.header_txt} data-qa-id="sign-in-name">
          {t('login.signIn')}
        </div>
        <input
          className={
            errors.userName
              ? globalStyles.user_cred_error
              : globalStyles.user_cred
          }
          type="text"
          onChange={handleChange}
          placeholder={t('login.username')}
          name="userName"
          autoFocus
          maxLength={30}
          disabled={accountLocked}
          ref={register({
            required: t('login.usernameRequired'),
          })}
        />
        {errors.userName ? (
          <div className={globalStyles.cred_req}>{errors.userName.message}</div>
        ) : (
          <div className={globalStyles.cred_req_ok} />
        )}
        <div className={formStyles.password_container}>
          <input
            className={
              errors.userPassword
                ? globalStyles.user_cred_error
                : globalStyles.user_cred
            }
            type="password"
            disabled={accountLocked}
            onChange={handleChange}
            placeholder={t('login.password')}
            name="userPassword"
            data-qa-id="user-password"
            ref={register({ required: true })}
          />
          {errors.userPassword ? (
            <div className={globalStyles.cred_req}>
              {t('login.passwordRequired')}
            </div>
          ) : null}
        </div>
        <div
          className={formStyles.forgot_link}
          data-qa-id="forgot-password-link"
          onClick={forgot}
        >
          Forgot password?
        </div>
        {!consentCookies && (
          <div className={formStyles.checkbox_container}>
            <div
              className={
                cookiesCheckmark ? formStyles.checked : formStyles.checkbox
              }
              onKeyPress={handleCheckBoxKeyPress}
              onClick={toggleAcceptCookies}
              tabIndex="0"
              data-qa-id="cookies-consent-checkbox"
            />
            <div className={styles.cookies_text}>
              {cookiesStatement}&nbsp;
              <a
                href="https://www.nyshex.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className={globalStyles.link_text}
              >
                {t('login.privacyPolicy')}
              </a>
            </div>
          </div>
        )}
        <div className={globalStyles.btn_container}>
          <input
            id="login"
            name="login"
            type="submit"
            className={
              !cookiesCheckmark
                ? `${styles.btn_main} ${styles.disabled_btn_main}`
                : styles.btn_main
            }
            disabled={loggingIn || !cookiesCheckmark || accountLocked}
            data-qa-id="log-in-button"
            ref={register}
            value={loggingIn ? t('login.loggingIn') + '...' : t('login.logIn')}
          />
        </div>
        {!whiteLabel && (
          <div className={globalStyles.body_txt}>
            {t('login.noAccountText')}&nbsp;
            <button
              onClick={handleClickRequestDemoLink}
              data-qa-id="sign-up"
              className={globalStyles.link_text}
            >
              Request a Demo
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default SignInForm;

SignInForm.propTypes = {
  loginError: PropTypes.string,
  loggingIn: PropTypes.bool,
  cookiesCheckmark: PropTypes.bool.isRequired,
  consentCookies: PropTypes.bool.isRequired,
  toggleAcceptCookies: PropTypes.func.isRequired,
  login: PropTypes.func,
  forgot: PropTypes.func.isRequired,
};
