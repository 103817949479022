import { axios } from 'index.js';

export const loginUser = (payload, config) => {
  return axios.post('/webapp/login', payload, config).then((res) => res);
};

export const missingConsent = () => {
  return axios.get('/webapp/self/missing-required-consent').then((res) => res);
};

export const giveConsent = (consentObj, headers) => {
  return axios
    .post('/webapp/self/give-consent', consentObj, headers)
    .then((res) => res);
};

export const refreshLogin = (headers) => {
  return axios
    .post('/webapp/refresh/refresh-jwt', {}, headers)
    .then((res) => res);
};
