import { datadogRum } from '@datadog/browser-rum';
import { createRoot } from 'react-dom/client';
import axiosInstance from 'axios';
import config from 'config';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { fetchApplicationVersion } from 'applicationVersionService';

if (config.ENV_NAME !== 'dev') {
  fetchApplicationVersion().then((version) => {
    datadogRum.init({
      applicationId: 'dd13c625-d114-49c1-9116-1976155285bc',
      clientToken: 'pub1f3c3d443664d46566c976befa7e3bb2',
      site: 'datadoghq.com',
      service: 'login-ui',
      env: config.ENV_NAME,
      version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  });
}

export const axios = axiosInstance.create({
  baseURL: config.API_DOMAIN,
});

axios.defaults.withCredentials = true;

const container =
  document.getElementById('root') || document.createElement('div');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
