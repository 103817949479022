import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    fontFamily: 'IBM Plex Sans',
    color: '#3E4043',
  },

  title: {
    fontSize: '24px',
    fontWeight: 400,
    borderBottom: '1px solid #E0E0E0',
  },

  text: {
    marginTop: '10px',
    lineHeight: '19px',
    fontWeight: 300,
    fontSize: '14px',
  },

  buttons: {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  cancel_btn: {
    backgroundColor: 'transparent',
    border: '1px solid #1E5B86',
    width: '111px',
    height: '38px',
    color: '#1E5B86',
    boxSizing: 'border-box',
    padding: '8px 0',
    textAlign: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    letterSpacing: '0.3px',
    marginLeft: '20px',
  },

  accept_btn: {
    width: '157px',
    background: '#193D9B',
    borderColor: '#193D9B',
    color: '#fff',
    '&:hover': {
      background: '#193D9B',
    },
  },

  link: {
    color: '#1E5B86',
    fontWeight: 400,
    textDecoration: 'underline',
  },
};

const AlertDialog = ({
  isOpen,
  toDeclineModal,
  acceptGdpr,
  closeModals,
  ...props
}) => {
  const { classes } = props;
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeModals();
          }
        }}
      >
        <DialogTitle className={classes.title}>
          Terms of Service and Privacy Policy
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.text}>
            We prioritize your privacy, which is why we’ve been GDPR compliant
            from the start and only store the data necessary to provide our
            services. We believe privacy is for everyone, so we’re committed to
            upholding the high standards of European data protection laws for
            all our users, regardless of location.
          </DialogContentText>
          <DialogContentText className={classes.text}>
            The&nbsp;
            <a
              target="_blank"
              href="https://www.nyshex.com/terms-of-use"
              rel="noopener noreferrer"
            >
              Platform Terms of Service
            </a>
            ,&nbsp;
            <a
              target="_blank"
              href="https://nyshex.com/proactive-performance-management-terms-of-service"
              rel="noopener noreferrer"
            >
              PPM Terms of Service
            </a>
            &nbsp;and&nbsp;
            <a
              target="_blank"
              href="https://www.nyshex.com/privacy-policy"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            &nbsp;will take effect immediately on your login date and, by using
            NYSHEX or any of our services on or after that date, you agree to
            these terms.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.cancel_btn} onClick={toDeclineModal}>
            Decline
          </Button>
          <Button
            className={`${classes.cancel_btn} ${classes.accept_btn}`}
            onClick={acceptGdpr}
          >
            Agree to Terms
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(AlertDialog);

AlertDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toDeclineModal: PropTypes.func.isRequired,
  acceptGdpr: PropTypes.func.isRequired,
  closeModals: PropTypes.func.isRequired,
};
