import globalStyles from 'assets/css/global_styles.css';
import maerskStyles from 'assets/css/maersk.css';
import mscStyles from 'assets/css/msc.css';
import hapagStyles from 'assets/css/hapag.css';
import cmaStyles from 'assets/css/cma.css';

export const getStyle = (whiteLabel) => {
  switch (whiteLabel) {
    case 'MAERSK':
      return maerskStyles;
    case 'MSC':
      return mscStyles;
    case 'HAPAG_LLOYD':
      return hapagStyles;
    case 'CMA_CGM':
      return cmaStyles;
    default:
      return globalStyles;
  }
};
