import { useState } from 'react';
import useForm from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import t from 'js_utils/translations';
import { resetPasswordRequest } from '../api/reset.api';
import { logErrorInfo } from 'RollbarErrorTracking';
import { getStyle } from 'js_utils/styles';
import LoginLink from 'components/shared/LoginLink';

import globalStyles from 'assets/css/global_styles.css';

const ForgotPasswordForm = (props) => {
  const [requesting, setRequesting] = useState(false);
  const [forgetPasswordError, setForgetPasswordError] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const styles = getStyle(props.whiteLabel);

  const onSubmit = (data) => {
    setRequesting(true);
    requestLink(data);
  };

  const requestLink = (data) => {
    if (!data) return;
    const payload = { userName: data.username };
    resetPasswordRequest(payload)
      .then(() => {
        props.history.push('./forgot-password-confirmation', {
          email: data.username,
        });
      })
      .catch((error) => {
        setRequesting(false);
        handleError(error);
      });
  };

  const handleError = (error) => {
    logErrorInfo(error);
    let forgetPasswordError;
    if (!error.response || !error.response.status) {
      forgetPasswordError = t('login.networkError');
    } else if (error.response.data && error.response.data.message) {
      forgetPasswordError = error.response.data.message;
    } else if (400 <= error.response.status && error.response.status < 500) {
      forgetPasswordError = t('login.clientError');
    } else if (500 <= error.response.status && error.response.status < 600) {
      forgetPasswordError =
        t('login.serverError') +
        ': ' +
        error.response.status +
        ' - ' +
        error.response.statusText;
    } else {
      forgetPasswordError = t('login.unexpectedError');
    }
    setForgetPasswordError(forgetPasswordError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {forgetPasswordError ? (
        <div className={globalStyles.error}>
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          <span
            className={
              forgetPasswordError.length < 60
                ? globalStyles.form_error
                : globalStyles.form_error_small
            }
          >
            {forgetPasswordError}
          </span>
        </div>
      ) : (
        <div className={globalStyles.no_error}></div>
      )}
      <div
        className={
          forgetPasswordError ? styles.rectangle_err : styles.rectangle
        }
      >
        <div>
          <div
            className={styles.header_txt}
            data-qa-id="forgot-password-header"
          >
            {t('login.forgotPassword')}
          </div>
          <div className={styles.subtitle}>
            {t('login.forgetPasswordUsernameLabel')}
          </div>
        </div>
        <input
          autoFocus
          className={
            requesting
              ? `${globalStyles.user_cred} ${globalStyles.disabled_input}`
              : errors.username
              ? globalStyles.user_cred_error
              : globalStyles.user_cred
          }
          type="text"
          placeholder={t('login.username')}
          name="username"
          disabled={requesting}
          ref={register({
            required: t('login.usernameRequired'),
            maxLength: {
              value: 255,
              message: t('login.maxLength255'),
            },
          })}
        />
        {errors.username ? (
          <div className={globalStyles.cred_req}>{errors.username.message}</div>
        ) : (
          <div className={globalStyles.cred_req_ok}></div>
        )}
        <div className={globalStyles.btn_container}>
          <input
            id="request-link"
            type="submit"
            className={
              requesting
                ? `${styles.btn_main} ${styles.disabled_btn_main}`
                : styles.btn_main
            }
            disabled={requesting}
            value={
              requesting
                ? t('login.requestingLink') + '...'
                : t('login.requestResetLink')
            }
          />
        </div>
        <LoginLink whiteLabel={props.whiteLabel} />
      </div>
    </form>
  );
};
export default withRouter(ForgotPasswordForm);
