import t from 'js_utils/translations';
import { Link } from 'react-router-dom';
import { getLoginUrl } from 'js_utils/functions';
import { getStyle } from 'js_utils/styles';

const LoginLink = ({ whiteLabel }) => {
  const styles = getStyle(whiteLabel);
  return (
    <div className={styles.body_txt}>
      {t('login.backTo')}&nbsp;
      <Link to={getLoginUrl(whiteLabel)} className={styles.link_text}>
        {t('login.logIn')}
      </Link>
    </div>
  );
};

export default LoginLink;
